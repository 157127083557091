<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true" />
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="appConfirmation" width="550" persistent>
      <v-card>
        <v-card-title class="headline">
          <span
            style="font-size:16px;font-weight:600"
          >Did you received the payment? once accepted can't be changed ?</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#00a972" text @click="appConfirmation = false">Cancel</v-btn>
          <v-btn color="red" text @click="ackPayment">Received</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout v-if="dataRetrived" wrap justify-center px-2>
      <v-flex pa-4 style="min-width:50vw">
        <v-card outlined>
          <v-layout px-8 py-4 wrap justify-center>
            <v-flex xs12 sm12 md12 text-center>
              <span
                style="font-size:26px;font-weight:500;color:#464646"
              >{{projectData.name}} - {{userData.username}}</span>
            </v-flex>
          </v-layout>
          <div v-for="(item,i) in userData.documents" :key="i">
            <v-layout px-4 wrap align-center>
              <v-flex xs12 sm12 md12 text-left>
                <a :href="baseURL+'/u/'+item.filename" target="_blank" download>
                  <span style="font-size:16px;font-weight:500;color:black">{{item.orginalname}}</span>
                </a>
                <span style="font-size:14px;font-weight:400;">&nbsp;({{item.name}}</span>
                <span
                  style="font-size:14px;font-weight:400;"
                  v-if="item.remark!='Accreditation Doc'"
                >&nbsp;-&nbsp;{{item.remark}})</span>
                <span style="font-size:14px;font-weight:400;" v-else>)</span>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
          </div>

          <!-- <v-layout px-4 wrap align-center>
            <v-flex pt-4 xs12 sm6 md6 text-center>
              <v-btn
                width="220"
                dark
                small
                color="#00a972"
                class="text-capitalize"
              >Request new Document</v-btn>
            </v-flex>
            <v-flex pt-4 xs12 sm6 md6 text-center>
              <v-btn
                width="220"
                dark
                small
                color="#00a972"
                class="text-capitalize"
              >Upload new Document</v-btn>
            </v-flex>
          </v-layout>-->
          <v-layout py-4>
            <v-flex></v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center px-2>
      <v-flex pa-4 style="min-width:50vw">
        <v-card outlined>
          <v-layout px-8 py-4 wrap justify-center>
            <v-flex xs12 sm12 md12 text-center>
              <span style="font-size:28px;font-weight:500;color:#464646">Manage Pledge Status</span>
            </v-flex>
          </v-layout>

          <v-layout px-4 wrap align-center>
            <v-flex xs9 sm9 md9 text-left>
              <span style="font-size:16px;font-weight:500;color:red">Payment Received ?</span>
            </v-flex>
            <v-flex xs3 sm3 md3 text-right>
              <v-switch
                v-model="paymentReceived"
                @click="appConfirmation=true"
                :disabled="paymentReceived"
                :label="paymentReceived ? 'Yes' : 'No'"
                :ripple="false"
                color="#00a972"
              ></v-switch>
            </v-flex>
          </v-layout>
          <v-divider></v-divider>

          <v-layout px-4 wrap align-center>
            <v-flex xs9 sm9 md8 text-left>
              <span style="font-size:16px;font-weight:500;color:red">Project Pledege Status</span>
            </v-flex>
            <v-flex xs3 sm3 md4 text-right>
              <v-select v-model="pledgeStatus" :items="pledgeStatuses" color="#00a972"></v-select>
            </v-flex>
          </v-layout>
          <v-divider></v-divider>
          <v-layout px-8 py-4 wrap justify-center>
            <v-flex xs12 sm12 md12 text-center>
              <v-btn width="220" dark small color="#00a972" @click="UpdatePledge">Submit</v-btn>
            </v-flex>
          </v-layout>
          <v-layout py-4>
            <v-flex></v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import VueElementLoading from "vue-element-loading";
import axios from "axios";
export default {
  props: ["id"],
  components: {
    VueElementLoading
  },
  data() {
    return {
      appLoading: false,
      appConfirmation: false,
      showSnackBar: false,
      timeout: 3000,
      msg: null,
      dataRetrived: false,
      paymentReceived: false,
      userData: {},
      projectData: {},
      pledgeStatus: null,
      pledgeStatuses: [
        "purchased",
        "declined",
        "under review",
        "cooling of period"
      ]
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/projectpledge/adminget",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        params: {
          id: this.id
        }
      })
        .then(response => {
          this.appLoading = false;
          this.paymentReceived = response.data.data.paymentReceived;
          this.pledgeStatus = response.data.data.pledgestatus;
          this.userData = response.data.data.user;
          this.projectData = response.data.data.project;
          this.dataRetrived = true;
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    ackPayment() {
      this.appConfirmation = false;
      this.paymentReceived = true;
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/projectpledge/acknowledge",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: {
          id: this.id
        }
      })
        .then(response => {
          this.appLoading = false;
          this.msg = response.data.msg;
          this.showSnackBar = true;
          this.getData();
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.showSnackBar = true;
        });
    },
    UpdatePledge() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/projectpledge/status",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: {
          id: this.id,
          status: this.pledgeStatus
        }
      })
        .then(response => {
          this.appLoading = false;
          this.msg = response.data.msg;
          this.showSnackBar = true;
          this.getData();
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.showSnackBar = true;
        });
    }
  }
};
</script>